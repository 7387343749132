import React from 'react';
import classNames from 'classnames/bind';
import styles from './index.module.scss';

const cx = classNames.bind(styles);

function PrivacyPolicy() {
	return (
		<div className={cx('wrapper')}>
			<h1 className={cx('title')}>Privacy</h1>
			<div className={cx('content')}>
				We only collect personal information that is reasonably necessary for, or directly related to, one or
				more of our functions of this website.
			</div>

			<div className={cx('link')}>How we collect information</div>

			<div className={cx('content')}>
				We collect personal information from individuals or their authorised representatives.
			</div>

			<div className={cx('content')}>
				In some circumstances we may collect personal information about individuals from third parties in the
				course of:
			</div>

			<ul>
				<li>preparing or receiving reports</li>
				<li>carrying out our compliance or investigation activities</li>
				<li>receiving other documents (such as those uploaded by our users and third parties)</li>
			</ul>
			<div className={cx('link')}>Use of personal information</div>

			<div className={cx('content')}>
				We only use personal information for the purpose for which it was collected, unless one of the following
				applies:
			</div>

			<ul>
				<li>we obtain the individual’s consent to use the personal information for a different purpose</li>
				<li>
					The individual would reasonably expect us to use the personal information for a different but
					related purpose (and if the personal information is sensitive information, that the purpose is
					directly related to the collection purpose)
				</li>
				<li>
					we are required or authorised by law to use the information (for example, by a court order or
					subpoena)
				</li>
				<li>
					a permitted general situation exists—including where we reasonably believe that using the
					information is necessary to:
					<ul>
						<li>
							lessen or prevent a serious threat to the life, health or safety of any individual or to
							public health or safety
						</li>
						<li>
							take appropriate action to correct suspected unlawful activities or serious misconduct in
							relation to our functions and activities, or establish a legal or equitable claim
						</li>
					</ul>
				</li>
				<li>
					we reasonably believe that the use is necessary for our enforcement activities (such as to gather
					intelligence or take enforcement action)
				</li>
			</ul>

			<div className={cx('content')}>
				We may be required or authorised to collect, use or disclose personal information by a variety of laws
				which include the Corporations Act 2001 (Corporations Act).
			</div>

			<div className={cx('link')}>Disclosure to other bodies or persons</div>

			<div className={cx('content')}>
				The types of bodies or persons to which we usually disclose personal information collected by us include
				the following:
			</div>

			<ul>
				<li>lawyers and other service providers who we engage to assist us with our functions</li>
				<li>other enforcement bodies (such as the Australian Federal Police)</li>
				<li>other government agencies (such as ASIC)</li>
				<li>courts and tribunals </li>
				<li>
					the public, if the personal information is required to be published in a register that can be
					searched by the public on our website
				</li>
				<li>applicants under the Freedom of Information Act 1982 (FOI Act) </li>
			</ul>

			<div className={cx('content')}>
				We only disclose personal information for the purpose for which it was collected, or for another
				purpose, if one of the following applies:
			</div>

			<ul>
				<li>the individual has consented to the disclosure</li>
				<li>
					the individual would reasonably expect us to disclose the personal information because it relates to
					the primary purpose for which it was collected
				</li>
				<li>we are required or authorised by law to disclose the information</li>
				<li>
					a permitted general situation exists—including where we reasonably believe that using the
					information is necessary to:
					<ul>
						<li>
							lessen or prevent a serious threat to the life, health or safety of any individual or to
							public health or safety
						</li>
						<li>
							take appropriate action to correct suspected unlawful activities or serious misconduct in
							relation to our functions and activities, or establish a legal or equitable claim
						</li>
						<li>
							lessen or prevent a serious threat to the life, health or safety of any individual or to
							public health or safety
						</li>
					</ul>
				</li>
				<li>
					we reasonably believe the disclosure is necessary for our enforcement activities, or for the
					enforcement activities of other Commonwealth, state or territory agencies.
				</li>
			</ul>

			<div className={cx('link')}>Storage and security of information</div>

			<div className={cx('content')}>We store personal information in electronic systems.</div>

			<div className={cx('content')}>
				We take steps to protect the personal information we hold against loss, unauthorised access, use,
				modification or disclosure, and against other misuse. These steps include password protection and access
				privileges for accessing our IT systems and physical access restrictions.
			</div>

			<div className={cx('content')}>
				If a datach occurs and personal information that we hold is subject to unauthorised loss, use or
				disclosure, we will respond in accordance with the Privacy Act.
			</div>

			<div className={cx('content')}>
				If we reasonably suspect that there has been such unauthorised access or disclosure, we will carry out
				an expeditious assessment to determine if it is an 'eligible datach' and take all reasonable steps to
				contain the unauthorised access or disclosure. We will complete our review within 30 days of becoming
				aware of the potential personal informatioach.
			</div>

			<div className={cx('content')}>When no longer required, we destroy personal information.</div>

			<div className={cx('link')}>Google Analytics and Google Tag Manager</div>

			<div>
				In addition to web service logs, we may use Google Analytics (including Google Analytics Advertising
				Features) and Google Tag Manager, which are web analysis services provided by Google Inc. (‘Google’).
			</div>

			<div className={cx('content')}>
				All the information we collect using Google Analytics and Google Tag Manager is for internal purposes
				only. We cannot identify individuals based on the data we collect and we will not publish any of it on
				our website.
			</div>

			<div className={cx('content')}>
				By using our website, you consent to Google processing data about you in the manner and for the purposes
				set out above.
			</div>

			<div className={cx('link')}>Email addresses</div>

			<div className={cx('content')}>
				We will record your email address only if you send us a message or enter it on a form. It will only be
				used or disclosed in accordance with our obligations under the Privacy Act, and will not be added to a
				mailing list unless you request that this be done.
			</div>

			<div className={cx('link')}> Subscriptions or logins</div>

			<div className={cx('content')}>
				To subscribe or login to some parts of our website, you must provide either a valid email address or a
				specific username and password. These details will only be used or disclosed in accordance with our
				obligations under the Privacy Act and will not be added to any other mailing lists unless you
				specifically ask us to. Email mailing list addresses are stored on a separate server and can be accessed
				by authorised staff only.
			</div>

			<div className={cx('link')}>Electronic newsletters</div>

			<div className={cx('content')}>
				When we send you an electronic newsletter that you have subscribed to, we have access to data about
				whether you opened that newsletter and clicked on links.
			</div>

			<div className={cx('link')}>Links to other websites</div>

			<div className={cx('content')}>
				We might include links to other sites, including social media sites (e.g. Facebook, YouTube and
				Twitter), to make it easy to share information. These other sites might use web measurement tools,
				customisation technologies and persistent cookies to inform the service they provide to their users. We
				are not responsible for the privacy practices or the content of other websites, and we do not use,
				maintain or share personal information that is collected by other websites.
			</div>

			<div className={cx('link')}>Quality, access and correction</div>

			<div className={cx('content')}>
				We will take reasonable steps to ensure that the personal information we hold is accurate, up to date,
				relevant and complete, including when it is used or disclosed.
			</div>

			<div className={cx('content')}>
				The Privacy Act allows an individual to seek access to their personal information and request that we
				correct their personal information where that information is inaccurate, out of date, incomplete,
				irrelevant or misleading. The FOI Act also sets out the process by which an individual can access,
				change or annotate documents we hold that contain their personal information.
			</div>

			<div className={cx('content')}>
				We are permitted to refuse a request to access or correct personal information where there are valid
				reasons under the Privacy Act, the FOI Act or other applicable law. If we refuse to provide access or
				correct personal information, we will notify the individual of our reasons and advise how they can
				request a review. Generally, an application under the Privacy Act for access will be acknowledged within
				30 days from the date that we receive it.
			</div>

			<div className={cx('content')}>
				You can obtain further information about how to request access or a correction to your personal
				information by emailing us at administration@statdemands.com.au .
			</div>

			<div className={cx('link')}>Complaints</div>
			<div>You may submit a complaint to us at administration@statdemands.com.au</div>
		</div>
	);
}

export default PrivacyPolicy;
