import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import styles from './index.module.scss';
import { Post_demand, Text } from '../../constants/text';
import { ToastContainer } from 'react-toastify';
import { AiOutlineDownload } from 'react-icons/ai';
import BaseAxios from '../../api/setUpAxios';
import { useParams } from 'react-router-dom';
import Loading from '../../components/loading';
import { ErrorMessage } from '@hookform/error-message';

const cx = classNames.bind(styles);

const DetailPostDemandComponent = () => {
	const [data, setData] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState(null);
	const { id } = useParams();

	useEffect(() => {
		document.title = 'Title_detail_demand | Statutory Demand';

		BaseAxios({
			url: `/demands/${id}/detail`,
			method: 'GET',
		})
			.then((response) => {
				console.log(response.data.data);
				setData(response.data.data);
				setIsLoading(false);
			})
			.catch((err) => {
				setError(err.message || 'Failed to fetch data');
				setIsLoading(false);
			});
	}, [id]);

	if (isLoading)
		return (
			<div className={cx('container')}>
				<Loading />
			</div>
		);

	if (error)
		return (
			<div className={cx('container', 'network-error')}>
				<div>{error}</div>
			</div>
		);

	return (
		<div className={cx('layout')}>
			<ToastContainer />
			{isLoading && <Loading />}
			{!isLoading && data && (
				<form className={cx('container')}>
					<h1 className={cx('title')}>{Post_demand.Title_detail_demand}</h1>
					<div className={cx('wrapper')}>
						<div className={cx('container_form_title')}>
							{data.debtor_company_name && (
								<div className={cx('text-field')}>
									<p>{Post_demand.debtor_company_name}</p>
									<div className={cx('input-field', 'input_debtor_company_name')}>
										<input
											type="text"
											className={cx('input')}
											value={data.debtor_company_name}
											readOnly
										/>
									</div>
								</div>
							)}

							{data.code_company && (
								<div className={cx('text-field')}>
									<div className={cx('text-column')}>
										<p className={cx('use-description')}>{Post_demand.acn_abn}</p>
									</div>
									<div className={cx('input-field', 'input_ACN_ABN')}>
										<input
											type="text"
											className={cx('input')}
											value={data.code_company}
											readOnly
										/>
									</div>
								</div>
							)}

							{data.creditor_name && (
								<div className={cx('text-field')}>
									<p>{Post_demand.creditor_name}</p>
									<div className={cx('input-field', 'input_creditor_name')}>
										<input
											type="text"
											className={cx('input')}
											value={data.creditor_name}
											readOnly
										/>
									</div>
								</div>
							)}

							{data.creditor_code_company && (
								<div className={cx('text-field')}>
									<div className={cx('text-column')}>
										<p className={cx('use-description')}>{Post_demand.creditor_ACN_ABN}</p>
									</div>
									<div className={cx('input-field', 'input_creditor_ACN_ABN')}>
										<input
											type="text"
											className={cx('input')}
											value={data.creditor_code_company}
											readOnly
										/>
									</div>
								</div>
							)}

							{data.demand_amount && (
								<div className={cx('text-field')}>
									<p>{Post_demand.demand_amount}</p>
									<div className={cx('input-field')}>
										<input
											type="text"
											className={cx('input')}
											value={data.demand_amount}
											readOnly
										/>
									</div>
								</div>
							)}

							{data.date_issued && (
								<div className={cx('text-field')}>
									<p>{Post_demand.date_issued}</p>
									<div className={cx('input-field')}>
										<input
											type="date"
											className={cx('input')}
											value={data.date_issued}
											readOnly
										/>
									</div>
								</div>
							)}

							{data.demand_pdf_required && (
								<div className={cx('text-field', 'download')}>
									<p className={cx('post-lable')}>{Post_demand.stat_demand_documents_1}</p>
									<div className={cx('file-upload-wrapper')}>
										<div className={cx('wrapper_icon')}>
											<a
												href={data.demand_pdf_required}
												target="_blank"
												rel="noopener noreferrer"
												className={cx('download-btn')}
												download
											>
												{Text.download} <AiOutlineDownload />
											</a>
											<p>{data.stat_demand_documents_required_pdf}</p>
										</div>
									</div>
								</div>
							)}

							{data.demand_pdf_optional && (
								<div className={cx('text-field', 'download')}>
									<p className={cx('post-lable')}>{Post_demand.stat_demand_documents_2}</p>
									<div className={cx('file-upload-wrapper')}>
										<div className={cx('wrapper_icon')}>
											<a
												href={data.demand_pdf_optional}
												target="_blank"
												rel="noopener noreferrer"
												className={cx('download-btn')}
												download
											>
												{Text.download} <AiOutlineDownload />
											</a>
											<p>{data.stat_demand_documents_optional_pdf}</p>
										</div>
									</div>
								</div>
							)}

							{data.comment && (
								<div>
									<p className={cx('additional-comments')}>{Post_demand.comments}</p>
									<div className={cx('input-field')}>
										<textarea
											className={cx('comments')}
											value={data.comment}
											readOnly
										></textarea>
									</div>
								</div>
							)}
						</div>
					</div>
				</form>
			)}
		</div>
	);
};

export default DetailPostDemandComponent;
