import React from 'react';
import classNames from 'classnames/bind';
import styles from './index.module.scss';
import { footer_text } from '../../constants/text';
const cx = classNames.bind(styles);

function Footer() {
	return (
		<footer className={cx('footer')}>
			<div className={cx('footer-content')}>
				<div className={cx('footer-section', 'medium')}>
					<h3 className={cx('footer-heading')}>{footer_text.disclaimer}</h3>
					<p className={cx('footer-text')}>{footer_text.disclaimer_item_1}</p>
					<p className={cx('footer-text')}>{footer_text.disclaimer_item_2}</p>
				</div>
				<div className={cx('footer-section', 'medium')}>
					<h3 className={cx('footer-heading')}>{footer_text.contact_footer}</h3>
					<ul className={cx('footer-list')}>
						<li>{footer_text.contact_footer_item_1}</li>
					</ul>
				</div>
				<div className={cx('footer-section', 'small')}>
					<h3 className={cx('footer-heading')}>{footer_text.privacy}</h3>

					<p>
						<a
							href="/privacy-policy"
							target="_blank"
							rel="noopener noreferrer"
							className={cx('footer-text', 'footer-text-link')}
						>
							{footer_text.privacy_item_3}
						</a>
					</p>
				</div>
			</div>
		</footer>
	);
}
export default Footer;
