const textProfileComponent = {
	label: {
		businessName: 'Representative Business Name',
		contactName: 'Contact Name',
		contactNumber: 'Contact Number',
		contactEmail: 'Registered Email',
		email: 'Email',
		oldPass: 'Current Password',
		newPass: 'New Password',
		autoSendEmail: 'Allow To Send Email Automatically Weekly',
		autoSendEmailDaily : 'Allow To Send Email Automaticall Daily'
	},
	placeHolder: {
		businessName: 'Please enter Representative Business Name',
		contactName: 'Please enter Contact Name',
		contactNumber: 'Please enter Contact Number',
		contactEmail: 'Please enter Contact Email',
		oldPass: 'Please enter Current Password',
		newPass: 'Please enter New Password',
	},
	errorValidate: {
		minimumText: 'Please enter more than 3 characters',
		minimumNumber: 'Please enter more than 10 numbers',
		formatEmailErr: 'Incorrect email format',
		emailErr: 'Please enter your email',
	},
};

const Data_text = {
	login_content: 'Welcome To Demands',
	login_title: 'Demands is a universe of 142,857 amazing creators',
	login_label: 'Have a password? Continue with your email address',
	forgot_password: 'I forgot my password',
	already_account: 'Already have an account?',
	input_useName: 'User name',
	placeHolder_useName: 'User name',
	required_useName: 'User name is required',
	input_email: 'Email',
	check_email: 'Incorrect email format',
	placeHolder_email: 'Email@gmail.com',
	required_email: 'Please enter your email',
	input_Password: 'Password',
	placeHolder_Password: 'Password...',
	required_Password: 'Please enter your password',
	required_Password_validate:
		'Password must contain special characters, letters uppercase, number and at least 8 characters.',
	required_Password_8_character: 'Must contain 8 characters',
	input_confirmPassword: 'Confirm Password',
	required_confirmPassword: 'Passwords must match',
	background_color_fb: '#3B5998',
	background_color_tw: '#1DA1F2',
	background_color_pe: '#BD081C',
	btn_create_account: 'Create Account',
	btn_login: 'Login',
	createAccount: `Create your Demand's account`,
};
const Post_demand = {
	Title_post_demand: 'Post Demand',
	demand_id: 'Demand ID',
	debtor_company_name: 'Debtor Company Name',
	acn_abn: 'ACN / ABN',
	creditor_name: 'Creditor Name',
	creditor_ACN_ABN: 'Creditor ACN / ABN',
	suggestion_number: '( Input 9 to 11 number )',
	demand_amount: 'Demand Amount $',
	date_issued: 'Date Issued',
	stat_demand_documents_1: 'Stat Demand Documents 1',
	stat_demand_documents_2: 'Stat Demand Documents 2',
	comments: 'Additional Comments',
	btn_save: 'Draft',
	save: 'Save',
	submit: 'Submit',
	btn_post: 'Post',
	upload: 'Upload',
	required_stat_demand_documents_1: 'You need to provide a file',
	required_comment_200_character: 'limited contain 200 characters',
	Title_detail_demand: 'Detail Demand',
};
const header_text = {
	homePage: 'Home',
	login: 'Login',
	sign_up: 'Sign Up',
	post_demand: 'Post Demand',
	edit_profile: 'Edit Profile',
	view_my_demands: 'View My Demands',
	logout: 'Log Out',
	textLogo: 'Statutory Demand',
	users: 'Manage Accounts',
	createUser: 'Create User',
	contact_details: 'Edit Account',
	settings: 'Settings',
};

const status_of_demand = {
	posted: 'Posted',
	waiting: 'Waiting Moderator Approve',
	Draft: 'Draft',
};

const footer_text = {
	contact_footer: 'Contact Details',
	contact_footer_item_1: 'Stat Demands Pty Ltd Level 8, 300 Queen Street Brisbane QLD 4000',
	// contact_footer_item_2: 'Phone: 1234 5678',
	// contact_footer_item_3: 'Email: info@example.com',
	// contact_footer_item_4: 'ABN: 12 345 678 901',
	disclaimer: 'Disclaimer',
	disclaimer_item_1:
		'Stat Demands Pty Ltd does not endorse or otherwise express an opinion about the content nor accuracy of any information or documents published by third parties and users on this website.\n Users of this website (including those without an account) are responsible for verifying any details published on this website by those persons who have uploaded it.\n We accept no liability whatsoever in that regard.',
	privacy: 'Privacy',
	privacy_item_3: 'Privacy policy',
};

const sidebar_text = [
	{
		body_sidebar_item: 'Sidebar item 1',
	},
	{
		body_sidebar_item: 'Sidebar item 2',
	},
	{
		body_sidebar_item: 'Sidebar item 3',
	},
	{
		body_sidebar_item: 'Sidebar item 4',
	},
];
const Users_demand = {
	title: {
		users_id: 'Users ID',
		business_name: 'Business Name',
		email: 'Email',
		phone: 'Phone Number',
		day: 'Day Create',
		role: 'Role',
		suspend: 'Suspended',
		auto_post: 'Trusted',
		suspended: 'Suspended',
		unsuspend: 'Unsuspend',
		btn_auto_post: 'Trusted',
		btn_not_auto_post: 'Untrusted',
		date_create: 'Date Created',
		contact_email: 'Contact Email',
		contact_name: 'Contact Name',
		verified: 'Verified',
		valid: 'Valid',
		inValid: 'Invalid',
	},
};

const Text = {
	title: {
		listOfDemands: 'List Of Demands',
		viewOfDemands: 'View My Demands',
	},
	titleCell: {
		demandId: 'Demand Id',
		debtorCompanyName: 'Debtor Company Name',
		acn: 'ACN',
		demandAmount: 'Demand Amount',
		dateIssued: 'Date Issued',
		expiresIn: 'Expires In',
		status: 'Status',
		action: 'Actions',
		posted: 'Approve',
		review: 'Review',
		deleted: 'Deleted',
		link: 'Link',
	},
	search: 'Search...',
	noData: 'This table has no data.',
	exampleData: {
		demand: {
			demandId: '2121 3222',
			debtorCompanyName: 'Debtor Company Pty Ltd',
			acn: '111 111 222',
			demandAmount: '20000$',
			dateIssued: '12/12/2022',
			expiresIn: '21 days',
		},
	},
	pagination: {
		next: 'Next',
		previous: 'Prev',
		breakLabel: '...',
	},
	backgroundMatteCoatingColor: 'rgba(3, 3, 3, 0.1)',
	delete: {
		confirm: 'Are you sure you want to delete this demand ?',
		confirm_delete_user: 'Are you sure you want to delete this user ?',
	},
	cancel: 'Cancel',
	ok: 'Yes',
	close: 'Close',
	download: 'Download Here',
	logo: 'Logo',
	noSupportVideo: 'Your browser does not support the video tag.',
	moveToSignUp: "You don't have account ?",
	signUpHere: 'Sign up here',
	moveToSignIn: 'You already have account ?',
	signInHere: 'Sign in here',
	public: 'Public',
	demandsForReview: 'Demands For Review',
	expiredDemands: 'Expired Demands',
	deletedDemands: 'Deleted Demands',
	active: 'active',
	noActive: 'noActive',
	banner: 'The Administrator will contact you to validate your account.',
	suspended: 0,
	no: 'NO',
	yes: 'YES',
	equal: '=',
	meanOfReview: 'Your Statutory Demand is being reviewed by the Administrator.',
	meanOfApproved: 'Your Statutory Demand has been approved and posted to the website.',
	approved: 'Approved',
	from20dayToExpired: 2,
	from15To19day: 6,
	expired: 'expired',
	liveUsers: 'Live Users',
	deletedUsers: 'Deleted Users',
	restore: 'Restore',
	backUpDB: 'Backup Database',
	backupPDFFilesOfUsers: 'Backup Uploaded PDF Files Of Users',
	colon: ':',
	after: 'After',
	download: 'Download',
	dot: '.',
	formatFile: {
		xlsx: 'xlsx',
		sql: 'sql',
	},
	backup: 'backup',
	uploadedPDFFilesOfUsers: 'uploaded-pdf-files-of-users',
};

export {
	Text,
	textProfileComponent,
	Data_text,
	header_text,
	footer_text,
	sidebar_text,
	status_of_demand,
	Post_demand,
	Users_demand,
};
